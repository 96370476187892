var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.appName,
          head: _vm.headAttributes,
          html: _vm.htmlAttributes
        }
      }),
      _c("gov-skip-link", { attrs: { href: "#main-content" } }, [
        _vm._v("Skip to main content")
      ]),
      _c("gov-header", {
        attrs: { "service-name": _vm.appName, navigation: _vm.headerNav }
      }),
      _c("div", { staticClass: "govuk-width-container" }, [
        _c(
          "main",
          {
            staticClass: "govuk-main-wrapper",
            class: _vm.mainClasses,
            attrs: { id: "main-content", role: "main" }
          },
          [_c("router-view")],
          1
        )
      ]),
      _c("gov-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }